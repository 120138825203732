import React from "react";
import { graphql, Link } from "gatsby";
import NavBarTwo from "../components/NavbarTwo";
import Footer from "../components/Footer";
import Image from "gatsby-plugin-sanity-image";
import { SEO } from "../components/seo";
import ContactDetails from "../components/ContactDetails";
import Topics from "../components/Topics";

const webentwicklung = ({ data }) => {
  return (
    <>
      <NavBarTwo />
      <section className="flex flex-col justify-end sm:block pb-16 text-gray-400 bg-gray-900 body-font bg-no-repeat bg-hero-webdev xl:bg-hero-webdev-xl bg-center	 bg-cover	 h-screen">
        <div className="container mx-auto max-w-7xl flex  px-5 sm:py-64 sm:flex-row flex-col items-center">
          <div className="lg:flex-grow max-w-sm md:max-w-lg md:w-1/2 -lg:pr-24 -md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center  bg-dark/40 py-6 px-4 rounded">
            <div class="w-12 h-1 bg-primary rounded mt-2 mb-4"></div>
            <h1 className="title-font sm:text-5xl text-3xl mb-4 font-medium text-white">Moderne Webseiten<span> </span>
              <br /><span className="text-primary">
                für Ihr Unternehmen</span>
            </h1>
            <br />
            <p className="text-sm md:text-base mb-8 leading-relaxed text-white max-w-sm">Wir verhelfen Ihnen zum  gelungenen Auftritt im Internet. Webseiten und Online Shops für KMU auf Basis moderner Technologien und Standards.</p>
            <div className="flex-col lg:flex-row justify-center">
              <a href="#main" className=" inline-flex text-white bg-primary border-0 py-2 px-6 focus:outline-none hover:bg-primary rounded-full text-lg mb-6">mehr erfahren
              </a>

              <a href="#contacts" className="sm:ml-0 lg:ml-4  inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded-full text-lg">Kontaktaufnahme</a>
            </div>
          </div>

        </div>
      </section>




      <section className="text-gray-600 body-font" id="main">
        <div className="container mx-auto max-w-7xl flex px-5 py-48 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <div class="w-12 h-1 bg-primary rounded mt-2 mb-4"></div>
            <h1 className=" title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Die Internet Agentur
              <br className="hidden lg:inline-block" /> im Raum Stuttgart
            </h1>
            <p className="sm:text-lg mb-8 leading-relaxed">{data.sanityLevelOne.intro_content}</p>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <Image className="rounded-sm" {...data.sanityLevelOne.image} width={302} height={192} style={{
              width: '100%',
              objectFit: 'cover',
              opacity: '1',
              transition: 'opacity 500ms ease 0s'
            }} alt="Internet Agentur Stuttgart" />
          </div>
        </div>
      </section>

      <section className="text-gray-50 body-font bg-dark bg-center bg-wavy bg-no-repeat bg-cover">
        <div id="contacts" className="container px-5 py-24 mx-auto flex flex-col mb-24 md:mb-64">
          <div className="lg:w-4/6 mx-auto">

            <div className="flex flex-col sm:flex-row mt-10">
              <ContactDetails />
              <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-primary sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left ">
                <p className="leading-relaxed text-lg mb-4 text-white">Sie möchten mehr über unsere Angebot an Webseiten und Onlineshops erfahren? Wir beraten plattformunabhängig mit Fokus auf Ihre individuellen Ziele und Anforderungen. Sichern Sie sich noch heute Ihr unverbindliche Erstgespräch. Mein Team und ich freuen uns auf Sie!  </p>
                <a className="cursor-pointer text-primary inline-flex items-center" href="mailto:hallo@digitscom.de?subject=Unverbindliches Erstgespräch: Webseiten und Onlineshop&body=Guten Tag Herr Kurz,%0D%0A%0D%0A
            ich interessiere mich für eine kostenlose Erstberatung zum Thema Websiten und Onlineshop.%0D%0A%0D%0A%0D%0ABitte kontaktieren Sie mich für eine kurzfristige Terminvereinbarung.%0D%0A(Bitte untenstehende Angaben vervollständigen.)%0D%0A%0D%0A%0D%0AName:%0D%0AFirma:%0D%0ATel.:%0D%0AE-Mail:%0D%0A">Jetzt Erstgespräch sichern
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </a>
                <div className="mark">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Topics />


      <Footer />
    </>
  )
}

export default webentwicklung;

export const Head = ({ data }) => {
  return (
    <>

      {/* <meta name="description" content="This is an example of a meta description. This will often show up in search results."/> */}
      {/* <title> </title> */}
      <SEO />
    </>
  )
}

export const dataWebDev = graphql`
query MyWebQuery {
  sanityLevelOne(id: {eq: "-c0476024-8b3a-534f-8169-7f33a3ab2ff4"}) {
    id
    image {
        ...ImageWithPreview
    }
    intro_content
    intro_title
    meta_description
    title
  }
}


`